import {
  baseColors,
  colors,
  EmotionStyles,
  grid,
  type,
  variables,
} from "@interactive-investor/onestack-web-shared";

export const styles: EmotionStyles = {
  buttonJoin: {
    "& button": {
      cursor: "pointer",
      fontSize: type.baseUnitMd,
      fontWeight: type.fontWeightBold,
    },
    "&:hover": {
      textDecoration: "none",
    },
    display: "none",
    [grid.breakpointMixins.md]: {
      display: "block",
    },
  },

  buttonLink: {
    background: "transparent",
    border: "none",
  },

  buttonJoinAuth: {
    display: "none",

    [grid.breakpointMixins.md]: {
      display: "block",
      fontSize: type.baseUnit,
      textAlign: "center",
      width: "110px",
    },
  },

  buttonLogin: {
    color: colors.white,
    marginRight: variables.marginMd,
    padding: 0,

    "&:hover": {
      color: colors.grey.light,
    },

    [grid.breakpointMixins.md]: {
      fontSize: type.baseUnit,
      margin: 0,
      padding: `0 ${variables.paddingMd}`,
    },
  },

  container: {
    background: colors.black.black,
    borderBottom: `5px solid ${baseColors.orange}`,
    color: colors.white,
    position: "relative",
    width: "100%",
    zIndex: 1,

    "& .GridRow": {
      alignItems: "center",
      height: variables.mobileSiteHeaderHeight,
      justifyContent: "space-between",

      [grid.breakpointMixins.md]: {
        height: variables.siteHeaderHeight,
      },
    },
  },

  brandingContainer: {
    display: "flex",
    justifyContent: "flex-start",
    order: 1,
    [grid.breakpointMixins.xl]: {
      maxWidth: "25%",
      width: "25%",
    },
  },

  branding: {
    margin: `0 ${variables.marginSm}`,
  },

  brand: {
    display: "inline-block",
    [grid.breakpointMixins.md]: {
      order: 1,
    },

    "& img": {
      height: "33px",
      marginRight: variables.paddingMd,
      width: "100px",
      [grid.breakpointMixins.md]: {
        height: "40px",
        width: "120px",
      },
    },
  },

  actions: {
    flexGrow: 1,
    order: 2,
    [grid.breakpointMixins.lg]: {
      flexGrow: 0,
      order: 3,
    },
    [grid.breakpointMixins.xl]: {
      maxWidth: "25%",
      width: "25%",
    },
  },

  actionsContainer: {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-evenly",
    paddingRight: variables.paddingMd,

    [grid.breakpointMixins.sm]: {
      justifyContent: "flex-end",
    },

    [grid.breakpointMixins.md]: {
      paddingRight: 0,
    },
  },

  accountDropdown: {
    color: colors.white,

    "&:hover": {
      color: colors.white,
    },

    "& span": {
      fontSize: type.textMicro.fontSize,

      [grid.breakpointMixins.md]: {
        fontSize: type.baseUnit,
      },
    },
  },

  accountDropdownChevron: {
    "& .svg-inline--fa": {
      ...type.chevronIcon,
      ...type.chevronIconRight,
      maxWidth: "1rem !important",
    },
  },

  ctaButton: {
    display: "none",
    [grid.breakpointMixins.lg]: {
      display: "inline",
    },
  },

  overlay: {
    background: colors.grey.dark,
    bottom: 0,
    left: 0,
    opacity: 0.8,
    position: "absolute",
    right: 0,
    top: variables.siteHeaderHeight,
  },

  auth: {
    alignItems: "center",
    display: "inline-flex",
    lineHeight: 1,
    listStyleType: "none",
    marginLeft: variables.marginMd,

    "& button:first-of-type": {
      [grid.breakpointMixins.sm]: {
        marginRight: "1rem",
      },
    },

    "& a, & span": {
      cursor: "pointer",

      "& i": {
        textDecoration: "none !important",
      },
    },

    "& .InlineModal__list__container": {
      width: "12.5rem",

      "& a, & span": {
        ...type.textSmall,
        color: colors.grey.dark,
        display: "block",
        padding: `${variables.paddingXs} 0`,

        "&:hover": {
          color: colors.secondaryAction,
        },
      },
    },
  },

  auth_prelogin_modal_actions: {
    borderBottom: `1px solid ${colors.whiteHover}`,
    margin: `0 -${variables.marginMd} ${variables.marginMd}`,
    paddingBottom: variables.paddingSm,
    paddingLeft: variables.paddingMd,
    paddingRight: variables.paddingMd,
    paddingTop: variables.paddingSm,

    "& p": {
      marginBottom: variables.marginMd,
      textAlign: "center",
    },
  },

  auth_prelogin_modal_step_2: {
    "& h4": {
      marginBottom: variables.marginSm,
    },
    "& p": {
      ...type.textSmall,
    },
  },

  auth_prelogin_modal_step_2_form: {
    marginBottom: variables.marginLg,

    "& .FormInput__input": {
      width: "80%",
    },
  },

  auth_prelogin_modal_cancel: {
    textAlign: "center",
  },

  forgotPasswordInput: {
    "& input": {
      width: "100%",
    },
  },

  forgotPasswordButton: {
    textAlign: "center",
  },

  searchMobile: {
    [grid.breakpointMixins.lg]: {
      display: "none",
    },
  },

  searchDesktop: {
    display: "none",
    [grid.breakpointMixins.lg]: {
      display: "flex",
    },
  },

  tradingLogin: {
    color: colors.white,
    fontSize: "0.9em",
    paddingLeft: 0,
    textAlign: "center",
    whiteSpace: "pre",
    width: "70px",

    [grid.breakpointMixins.md]: {
      fontSize: type.baseUnit,
      width: "140px",
    },
  },
};
