import { Location } from "@reach/router";
import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import { Helmet } from "react-helmet";

import {
  CookieBar,
  createFaLibrary,
  ErrorBoundary,
  HotKeys,
  SiteSearchModal,
} from "@interactive-investor/onestack-web-shared";

import EmotionWrapper from "../EmotionWrapper";
import SiteFooter from "../SiteFooter";
import SiteHeader from "../SiteHeader";

interface Props {
  children: JSX.Element;
}

const Layout = (props: Props) => {
  const [mounted, setMounted] = React.useState(false);

  React.useEffect(() => {
    createFaLibrary();
    setMounted(true);
  }, []);

  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
      }
    `
  );

  const { children } = props;
  const tradeLink: string = "https://secure.ii.co.uk/";

  return (
    <EmotionWrapper>
      <ErrorBoundary tradeLink={tradeLink}>
        <Helmet
          htmlAttributes={{ lang: "en" }}
          title={site.siteMetadata.title}
        />
        <div id="site-modal-container" />
        <div id="site-layout-container" className="layout-container">
          <HotKeys>
            <SiteHeader
              siteTitle={site.siteMetadata.title}
              siteUrl={site.siteMetadata.siteUrl}
            />

            <div id="site-content-container">
              <Location>
                {({ location }) => {
                  return (
                    <main
                      className={`site-main ${
                        location.pathname === "/" ? "site-main--homepage" : ""
                      }`}
                      role="main"
                    >
                      <ErrorBoundary tradeLink={tradeLink}>
                        {children}
                      </ErrorBoundary>
                    </main>
                  );
                }}
              </Location>

              {mounted && <SiteFooter />}
            </div>

            <SiteSearchModal
              instrumentBaseUrl={process.env.GATSBY_MAIN_SITE_URL}
              instrumentBaseUrlIsExternal={true}
            />
          </HotKeys>

          {mounted && <CookieBar />}
        </div>
      </ErrorBoundary>
    </EmotionWrapper>
  );
};

export default Layout;
