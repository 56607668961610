import React from "react";

import {
  SiteSearchNav,
  withLayout,
  LayoutContextInjectedProps,
  MainWebNavUk,
} from "@interactive-investor/onestack-web-shared";

import SiteHeaderAuth from "./components/Auth/";
import Logo from "./components/Logo/";

import { SiteHeaderProps } from "./interfaces/props";

import { mainMenuLinks } from "./links";
import { styles } from "./styles";

class SiteHeader extends React.Component<
  SiteHeaderProps & LayoutContextInjectedProps
> {
  render() {
    const { layout } = this.props;

    return (
      <header css={styles.container} role="banner">
        <div className="GridRow">
          <div css={styles.brandingContainer}>
            <Logo siteTitle={this.props.siteTitle} layout={layout} />
            <div css={styles.searchDesktop}>
              <SiteSearchNav
                onMobile={layout.onMobile}
                breakpoint={layout.breakpoint}
              />
            </div>
          </div>

          <MainWebNavUk links={mainMenuLinks} />

          <div css={styles.actions}>
            <div css={styles.actionsContainer}>
              <SiteHeaderAuth onMobile={layout.onMobile} />
              <div css={styles.searchMobile}>
                <SiteSearchNav
                  onMobile={layout.onMobile}
                  breakpoint={layout.breakpoint}
                />
              </div>
            </div>
          </div>
        </div>
      </header>
    );
  }
}

export default withLayout<SiteHeaderProps>()(SiteHeader);
