import {
  baseColors,
  colors,
  EmotionStyles,
  getWidth,
  grid,
  type,
  variables,
} from "@interactive-investor/onestack-web-shared";

export const styles: EmotionStyles = {
  container: {
    background: colors.black.altBg,
    borderTop: `5px solid ${baseColors.orange}`,
    textAlign: "left",
  },

  backToTop: {
    backgroundColor: colors.black.black,
    color: colors.white,
    cursor: "pointer",
    fontWeight: type.fontWeightBold,
    paddingBottom: variables.paddingSm,
    paddingTop: variables.paddingSm,
    textAlign: "center",
  },

  backToTopArrow: {
    ...type.chevronIcon,
    ...type.chevronIconLeft,
  },

  main: {
    backgroundColor: colors.white,
    paddingBottom: variables.paddingLg,
    paddingTop: variables.paddingLg,
  },

  sections: {
    marginBottom: variables.marginLg,
  },

  sectionLabel: {
    fontWeight: type.fontWeightBold,
    marginBottom: variables.marginSm,
  },

  link: {
    color: colors.primaryLink,
    display: "block",
    ...type.textSmall,
    marginBottom: variables.marginSm,
  },

  copyright: {
    ...type.textTiny,
  },

  appImages: {
    paddingTop: variables.paddingMd,
    textAlign: "center",
    [grid.breakpointMixins.sm]: {
      paddingTop: 0,
      textAlign: "right",
    },
  },

  appImage: {
    height: "40px",
    marginLeft: variables.marginSm,
  },

  social: {
    background: colors.black.black,
    color: colors.white,
    paddingBottom: variables.paddingMd,
    paddingTop: variables.paddingMd,
  },

  socialContainer: {
    display: "flex",
  },

  logo: {
    flexGrow: 1,
  },

  heroImage: {
    height: "40px",
  },

  socialIcons: {
    alignItems: "center",
    display: "flex",
  },

  socialIcon: {
    background: colors.white,
    borderRadius: "50%",
    color: colors.grey.dark,
    display: "inline-block",
    fontSize: "24px",
    height: "32px",
    marginLeft: variables.marginMd,
    position: "relative",
    width: "32px",
  },

  socialIconIcon: {
    left: "50%",
    maxHeight: type.baseUnit,
    maxWidth: type.baseUnit,
    position: "absolute",
    top: "50%",
    transform: "translate(-50%, -50%)",
  },

  footerLinks: {
    display: "block",
    marginLeft: variables.marginSm,
    marginRight: variables.marginSm,
    ...getWidth(100),

    [grid.breakpointMixins.sm]: {
      ...getWidth(50),
    },

    [grid.breakpointMixins.md]: {
      ...getWidth(33.33333),
    },

    [grid.breakpointMixins.xl]: {
      ...getWidth(20),
    },
  },
};
