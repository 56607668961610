import * as React from "react";

import {
  Img,
  LayoutContextInjectedProps,
  ModalContextInjectedProps,
  OneSiteLink,
  withModal,
} from "@interactive-investor/onestack-web-shared";

import { styles } from "../../styles";

interface LogoProps {
  siteTitle: string;
}

export type MergedProps = LogoProps &
  ModalContextInjectedProps &
  LayoutContextInjectedProps;

const logoImg = `${process.env.PUBLIC_URL || ""}/static/images/site-logo.svg`;
class Logo extends React.Component<MergedProps> {
  render() {
    return (
      <div css={styles.branding}>
        <OneSiteLink
          cssStyles={[styles.brand]}
          to="/"
          title={this.props.siteTitle}
          rel="home"
          testId="site-header-logo-link"
          onClick={() =>
            setTimeout(() => {
              this.props.modal.toggleNav(false, this.props.layout.breakpoint);
            }, 300)
          }
        >
          <Img alt={this.props.siteTitle} src={logoImg} role="img" />
        </OneSiteLink>
      </div>
    );
  }
}

export default withModal<MergedProps>()(Logo);
