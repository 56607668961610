import classnames from "classnames";
import * as React from "react";

import { sections } from "./links";

import {
  getYear,
  Icon,
  Img,
  OneSiteLink,
} from "@interactive-investor/onestack-web-shared";

import { FooterLink, LinksSection } from "./interfaces/links";
import { SiteFooterProps } from "./interfaces/props";

import { withAuth } from "../../contexts/Auth";
import { AuthContextInjectedProps } from "../../contexts/Auth/interfaces/context";
import { styles } from "./styles";

type MergedProps = SiteFooterProps & AuthContextInjectedProps;

const SiteFooter = (props: MergedProps) => {
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  const { auth } = props;

  return (
    <footer css={styles.container} role="contentinfo">
      <div css={styles.backToTop} onClick={() => scrollToTop()}>
        <Icon
          cssStyles={[styles.backToTopArrow]}
          identifier="chevron-up"
          set="fas"
        />
        <span>Back to top</span>
      </div>

      <div css={styles.main}>
        <div css={styles.sections} className={classnames("GridRow")}>
          {sections({ auth }).map((section: LinksSection, index: number) => (
            <div
              key={index}
              css={styles.footerLinks}
            >
              <div css={styles.sectionLabel}>{section.sectionLabel}</div>
              <div>
                {section.links.map((link: FooterLink, secondIndex) =>
                  link.external ? (
                    <a
                      key={secondIndex}
                      css={styles.link}
                      href={link.url}
                      title={link.title ? link.title : link.label}
                      target={"_blank"}
                      onClick={
                        link.onClick as (
                          event?: React.MouseEvent | undefined
                        ) => void
                      }
                    >
                      {link.label}
                    </a>
                  ) : (
                    <OneSiteLink
                      cssStyles={[styles.link]}
                      key={secondIndex}
                      onClick={link.onClick}
                      to={link.url || ""}
                      title={link.title || link.label}
                    >
                      {link.label}
                    </OneSiteLink>
                  )
                )}
              </div>
            </div>
          ))}
        </div>

        <div className="GridRow">
          <div
            css={styles.copyright}
            className={classnames("GridCell-xs-12 GridCell-sm-6")}
          >
            &copy; 2018 - {getYear()} Interactive Investor Services Limited. All
            Rights Reserved. Interactive Investor Services Limited is authorised
            and regulated by the Financial Conduct Authority.
          </div>
          <div
            css={styles.appImages}
            className={classnames("GridCell-xs-12 GridCell-sm-6")}
          >
            <a
              title="Download the Interactive Investor app from the Apple App Store"
              href="https://itunes.apple.com/gb/app/td-trading/id434714838?mt=8"
            >
              <Img
                css={styles.appImage}
                alt="Download the Interactive Investor app from the Apple App Store"
                src={`${process.env.PUBLIC_URL || ""}/static/images/apple.png`}
              />
            </a>
            <a
              title="Download the Interactive Investor app from Google Play"
              href="https://play.google.com/store/apps/details?id=uk.co.interactiveinvestor.apps.iiandroidapp"
            >
              <Img
                css={styles.appImage}
                alt="Download the Interactive Investor app from Google Play"
                src={`${process.env.PUBLIC_URL || ""}/static/images/google.png`}
              />
            </a>
          </div>
        </div>
      </div>

      <div css={styles.social}>
        <div className="GridRow">
          <div
            css={styles.socialContainer}
            className={classnames("GridCell-xs-auto")}
          >
            <div css={styles.logo}>
              <OneSiteLink to="/" title="Interactive Investor">
                <Img
                  css={styles.heroImage}
                  alt="Interactive Investor"
                  src={`${
                    process.env.PUBLIC_URL || ""
                  }/static/images/site-logo.svg`}
                />
              </OneSiteLink>
            </div>
            <div css={styles.socialIcons}>
              <a
                title="Interactive Investor's Facebook"
                target="_blank"
                href="http://www.facebook.com/interactiveinvestors"
                css={styles.socialIcon}
              >
                <Icon
                  identifier="facebook-f"
                  set="fab"
                  cssStyles={[styles.socialIconIcon]}
                />
              </a>
              <a
                title="Interactive Investor's Twitter"
                target="_blank"
                href="https://twitter.com/ii_couk"
                css={styles.socialIcon}
              >
                <Icon
                  identifier="twitter"
                  set="fab"
                  cssStyles={[styles.socialIconIcon]}
                />
              </a>
              <a
                title="Interactive Investor's LinkedIn"
                target="_blank"
                href="https://www.linkedin.com/company/interactive-investor"
                css={styles.socialIcon}
              >
                <Icon
                  identifier="linkedin-in"
                  set="fab"
                  cssStyles={[styles.socialIconIcon]}
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default withAuth<SiteFooterProps>(SiteFooter);
