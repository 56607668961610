import { AuthContextInterface } from "../../contexts/Auth/interfaces/context";

import { LinksSection } from "./interfaces/links";

interface FooterSectionParams {
  auth: AuthContextInterface;
}

export const sections = ({ auth }: FooterSectionParams) => [
  {
    sectionLabel: "Investment Accounts",
    links: [
      {
        label: "Open a Trading Account",
        url: `${process.env.GATSBY_MAIN_SITE_URL}/ii-accounts/trading-account`,
        external: true,
      },
      {
        label: "Open a SIPP",
        url: `${process.env.GATSBY_MAIN_SITE_URL}/ii-accounts/sipp`,
        external: true,
      },
      {
        label: "Stocks & Shares ISA",
        url: `${process.env.GATSBY_MAIN_SITE_URL}/ii-accounts/isa`,
        external: true,
      },
      {
        label: "Open a Junior ISA",
        url: `${process.env.GATSBY_MAIN_SITE_URL}/ii-accounts/isa/junior-isa`,
        external: true,
      },
      {
        label: "See all Investment Accounts",
        url: `${process.env.GATSBY_MAIN_SITE_URL}/ii-accounts`,
        external: true,
      },
    ],
  },
  {
    sectionLabel: "Investing with ii",
    links: [
      {
        label: "Transfer Pension to SIPP",
        url: `${process.env.GATSBY_MAIN_SITE_URL}/ii-accounts/sipp/transfer-my-pension`,
        external: true,
      },
      {
        label: "Transfer Stocks & Shares ISA",
        url: `${process.env.GATSBY_MAIN_SITE_URL}/ii-accounts/isa/transfer-isa`,
        external: true,
      },
      {
        label: "Transferring to ii",
        url: `${process.env.GATSBY_MAIN_SITE_URL}/investing-with-ii/transferring-to-ii`,
        external: true,
      },
      {
        label: "International Investing",
        url: `${process.env.GATSBY_MAIN_SITE_URL}/investing-with-ii/international-investing`,
        external: true,
      },
      {
        label: "Regular Investing",
        url: `${process.env.GATSBY_MAIN_SITE_URL}/investing-with-ii/regular-investing`,
        external: true,
      },
      {
        label: "Ethical Investing",
        url: `${process.env.GATSBY_MAIN_SITE_URL}/ethical-investing`,
        external: true,
      },
      {
        label: "IPOs",
        url: `${process.env.GATSBY_MAIN_SITE_URL}/ipos`,
        external: true,
      },

    ],
  },
  {
    sectionLabel: "Useful Links",
    links: [
      {
        label: "Account Log In",
        url: "https://secure.ii.co.uk/webbroker2/",
        external: true,
      },
      {
        label: "Research Log In",
        url: "/login",
        external: false,
        onClick: (event?: MouseEvent) => {
          if (event) {
            event.preventDefault();
          }
          // Replicate functionality in SiteHeaderAuth
          auth.loginFree();
        },
      },
      {
        label: "Mobile Trading App",
        url: `${process.env.GATSBY_MAIN_SITE_URL}/mobile-apps`,
        external: true,
      },
      {
        label: "Press Contacts",
        url: `${process.env.GATSBY_MAIN_SITE_URL}/press-contacts`,
        external: true,
      },
      {
        label: "About ii",
        url: `${process.env.GATSBY_MAIN_SITE_URL}/about-ii`,
        external: true,
      },
    ],
  },
  {
    sectionLabel: "Important Information",
    links: [
      {
        label: "Disclosures",
        url: `${process.env.GATSBY_MAIN_SITE_URL}/disclosures`,
        external: true,
      },
      {
        label: "Legal Terms",
        url: `${process.env.GATSBY_MAIN_SITE_URL}/legal-terms`,
        external: true,
      },
      {
        label: "Privacy",
        url: `${process.env.GATSBY_MAIN_SITE_URL}/privacy`,
        external: true,
      },
      {
        label: "Cookies",
        url: `${process.env.GATSBY_MAIN_SITE_URL}/cookies`,
        external: true,
      },
      {
        label: "Security Policy",
        url: `${process.env.GATSBY_MAIN_SITE_URL}/security`,
        external: true,
      },
      {
        label: "Security Hub",
        url: `${process.env.GATSBY_MAIN_SITE_URL}/security-hub`,
        external: true,
      },
    ],
  },
  {
    sectionLabel: "Need Help",
    links: [
      {
        label: "Help",
        url:
          "https://help.ii.co.uk/system/templates/selfservice/ii/help/customer/locale/en-GB/portal/402800000001013",
        external: true,
      },
      {
        label: "Contact Us",
        url: `${process.env.GATSBY_MAIN_SITE_URL}/contact-us`,
        external: true,
      },
      {
        label: "Careers",
        url: `${process.env.GATSBY_MAIN_SITE_URL}/jobs-at-ii`,
        external: true,
      },
      {
        label: "Useful Forms",
        url: `${process.env.GATSBY_MAIN_SITE_URL}/useful-forms`,
        external: true,
      },
      {
        label: "Site Map",
        url: `${process.env.GATSBY_MAIN_SITE_URL}/site-map`,
        external: true,
      },
    ],
  },
] as LinksSection[];
