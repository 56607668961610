import * as React from "react";
import * as validator from "validator";

import {
  Button,
  GTMService,
  Input,
  MenuDropdown,
  Modal,
  ModalHeader,
  navigateTo,
  OneSiteLink,
} from "@interactive-investor/onestack-web-shared";

import { withAuth } from "../../../../contexts/Auth";

import { AuthContextInjectedProps } from "../../../../contexts/Auth/interfaces/context";

import { styles } from "../../styles";

export interface SiteHeaderAuthProps {
  onMobile: boolean;
}

export interface SiteHeaderAuthState {
  mounted: boolean;
  showPreLoginModal: boolean;
  preLoginModalStep2Show: boolean;
  forgotPasswordEmail: string;
  forgotPasswordEmailError: string | undefined;
}

export type MergedProps = SiteHeaderAuthProps & AuthContextInjectedProps;

class SiteHeaderAuth extends React.Component<MergedProps, SiteHeaderAuthState> {
  constructor(props: MergedProps) {
    super(props);

    this.state = {
      forgotPasswordEmail: "",
      forgotPasswordEmailError: undefined,
      mounted: false,
      preLoginModalStep2Show: false,
      showPreLoginModal: false,
    };
  }

  componentDidMount() {
    this.setState({ mounted: true });
  }

  onTogglePreLoginModal = () =>
    this.setState(
      {
        forgotPasswordEmail: "",
        preLoginModalStep2Show: false,
        showPreLoginModal: !this.state.showPreLoginModal,
      },
      () => {
        GTMService.pushEvent("LoginModal", {
          action: this.state.showPreLoginModal ? "Show modal" : "Hide modal",
        });
      }
    );

  onLinkAway = (link: string) => {
    this.onTogglePreLoginModal();

    this.setState({
      preLoginModalStep2Show: false,
    });

    navigateTo(link);
  };

  onShowPreLoginModalStep2 = () => {
    this.setState({
      preLoginModalStep2Show: true,
    });
    GTMService.pushEvent("LoginModal", {
      action: "Existing research account first log in",
    });
  };

  onForgotPasswordEmailChange = (email: string) => {
    this.setState({
      forgotPasswordEmail: email,
    });
  };

  onForgotPasswordEmailSubmit = () => {
    if (validator.isEmail(this.state.forgotPasswordEmail)) {
      this.setState({
        forgotPasswordEmailError: undefined,
      });

      GTMService.pushEvent("LoginModal", {
        action: "Reset Password",
      });
      this.props.auth
        .migratedUserPasswordReset(this.state.forgotPasswordEmail)
        .then(() => {
          this.onTogglePreLoginModal();
        });
    } else {
      this.setState({
        forgotPasswordEmailError: "Please enter a valid email address",
      });
    }
  };

  render() {
    const { auth, onMobile } = this.props;

    let faqUrl = "https://help.ii.co.uk/system/templates/selfservice/ii/";
    faqUrl += "help/customer/locale/en-GB/portal/402800000001013";

    if (auth.processingLogin) {
      return null;
    }

    return (
      <nav
        role="navigation"
        css={styles.auth}
        data-testid="site-header-auth-buttons"
      >
        <Modal
          id="site-header-pre-login-modal"
          show={this.state.showPreLoginModal}
          size="small"
          onClose={() => this.setState({ showPreLoginModal: false })}
        >
          {!this.state.preLoginModalStep2Show && (
            <>
              <div css={styles.auth_prelogin_modal_actions}>
                <p>
                  1. <span className="text-bold">Virtual portfolio</span> &amp;{" "}
                  <span className="text-bold">newsletters</span>
                </p>
                <p>
                  <Button
                    type="primary"
                    size="medium"
                    onClick={() => {
                      GTMService.pushEvent("LoginModal", {
                        action: "Research Login",
                      });
                      auth.loginFree();
                    }}
                  >
                    research account login
                  </Button>
                </p>
              </div>
              <div
                css={[
                  styles.auth_prelogin_modal_step_2,
                  styles.auth_prelogin_modal_actions,
                ]}
              >
                <h4>
                  2.{" "}
                  <span className="text-bold">
                    Existing research account first log in?
                  </span>
                </h4>
                <p>
                  If you are{" "}
                  <span className="text-bold">
                    accessing your research account for the first time{" "}
                  </span>
                  since the launch of our new website in June 2018, please{" "}
                  <button
                    css={styles.buttonLink}
                    className="Link text-bold"
                    onClick={() => this.onShowPreLoginModalStep2()}
                  >
                    click here
                  </button>
                  .
                </p>
              </div>
              <div css={styles.auth_prelogin_modal_actions}>
                <p>
                  3. To log in to the{" "}
                  <span className="text-bold">secure trading platform</span>
                  {/* <span
                    style={{
                      display: "block",
                      fontSize: "13px",
                      lineHeight: 1.3,
                      color: "#EB143F",
                      marginTop: "1rem"
                    }}
                  >
                    {/* We are currently experiencing a technical issue affecting our secure trading{" "}
                    platform. However our mobile application is currently unaffected. Our technical teams{" "}
                    are working on resolving the issue. Sorry for any inconvenience. * /}
                    Our secure services, including online account opening, are currently unavailable while{" "}
                    we make some improvements. Please try again later. We’re sorry for any inconvenience.
                  </span> */}
                </p>
                <p>
                  <OneSiteLink
                    className="btn btn-secondary btn-medium"
                    to="https://secure.ii.co.uk"
                    target="_blank"
                    title="Trading Account Log In"
                    onClick={() => {
                      this.onTogglePreLoginModal();
                      GTMService.pushEvent("LoginModal", {
                        action: "Trading Login",
                      });
                    }}
                  >
                    trading account login
                  </OneSiteLink>
                </p>
              </div>
            </>
          )}

          {this.state.preLoginModalStep2Show && (
            <>
              <ModalHeader title="First log in to our new website?" />

              <p className="text-small">
                If you logged into your research account{" "}
                <strong>between June 2017 and June 2018</strong>, use the
                password reset button below to regain access to your account.
              </p>

              <form
                css={styles.auth_prelogin_modal_step_2_form}
                onSubmit={(e) => {
                  e.preventDefault();
                  this.onForgotPasswordEmailSubmit();
                }}
              >
                <Input
                  type="email"
                  value={this.state.forgotPasswordEmail}
                  name="forgotPasswordEmail"
                  helper="Please enter your registered email address to
                  move your account and reset your password"
                  label="Registered email address"
                  onChange={(email: string) =>
                    this.onForgotPasswordEmailChange(email)
                  }
                  error={this.state.forgotPasswordEmailError}
                  cssStyles={[styles.forgotPasswordInput]}
                />

                <p css={styles.forgotPasswordButton}>
                  <Button type="primary" size="medium">
                    password reset
                  </Button>
                </p>
              </form>

              <p className="text-small">
                If your last log in to your research account was before June
                2017, please{" "}
                <OneSiteLink
                  to="/register"
                  className="Link"
                  onClick={() => {
                    GTMService.pushEvent("LoginModal", {
                      action: "Register (before June 2017)",
                    });
                  }}
                  title="register for a new account"
                >
                  register for a new account
                </OneSiteLink>
                .
              </p>

              <p className="text-small">
                For help and more information about accessing your account
                please visit our{" "}
                <OneSiteLink
                  className="Link text-lowercase"
                  onClick={() => {
                    GTMService.pushEvent("LoginModal", {
                      action: "FAQ",
                    });
                  }}
                  to={faqUrl}
                  title="FAQ page"
                  target="_blank"
                >
                  FAQ page
                </OneSiteLink>
                .
              </p>
            </>
          )}

          <div css={styles.auth_prelogin_modal_cancel}>
            <Button
              type="link"
              size="medium"
              onClick={() => this.onTogglePreLoginModal()}
            >
              cancel
            </Button>
          </div>
        </Modal>

        {!auth.isAuthenticatedFree() && this.state.mounted && (
          <>
            <Button
              type="link"
              size="medium"
              leftIcon="lock"
              leftIconSet="fas"
              responsive={onMobile}
              transparent={onMobile}
              onClick={() => this.onTogglePreLoginModal()}
              cssStyles={[styles.buttonLogin]}
            >
              Log in
            </Button>
          </>
        )}

        {!this.state.mounted && (
          <>
            <OneSiteLink
              cssStyles={[styles.tradingLogin]}
              to="https://secure.ii.co.uk"
              target="_blank"
              title="Trading Account Log In"
            >
              {onMobile ? "Trading\n Log In" : "Trading Account Log In"}
            </OneSiteLink>{" "}
          </>
        )}

        {auth.isAuthenticatedFree() && (
          <MenuDropdown
            showArrow={false}
            size={"medium"}
            alignToParent={"right"}
            label={
              <Button
                type="link"
                size="medium"
                leftIcon="user"
                leftIconSet="fas"
                rightIcon={onMobile ? undefined : "chevron-down"}
                rightIconSet="fas"
                responsive={onMobile}
                transparent={true}
                normalWeight={true}
                cssStyles={[styles.accountDropdown]}
                rightIconWrapperClass={styles.accountDropdownChevron}
              >
                Account
              </Button>
            }
            items={[
              {
                icon: "binoculars",
                iconSet: "fal",
                label: "Virtual portfolio",
                link: "/virtual-portfolio",
              },
              {
                icon: "handshake",
                iconSet: "fal",
                label: "Trading log in",
                link: "https://secure.ii.co.uk",
                linkNewWindow: true,
              },
              {
                icon: "cog",
                iconSet: "fas",
                label: "Settings",
                link: "/settings",
                linkNewWindow: false,
              },
              {
                icon: "unlock",
                iconSet: "far",
                label: "Research log out",
                onClick: () => auth.logout(),
              },
            ]}
          />
        )}
      </nav>
    );
  }
}

export default withAuth(SiteHeaderAuth);
