export const mainMenuLinks = [
  { label: "Virtual Portfolio", url: "/virtual-portfolio" },

  { label: "Newsletters", url: "/newsletter-preferences" },

  {
    label: "Main site",
    title: "ii main website",
    url: process.env.GATSBY_MAIN_SITE_URL,
    external: true,
    target: "_blank",
  },
];
